import React, {useEffect} from "react"
import { Link } from "gatsby-plugin-intl"

import {useIntl} from "react-intl";
import PropTypes from "prop-types";

import Slider from "react-slick";

const RewardSlider = ({rewards}) => {
  const intl = useIntl();

  const settings1 = {
    dots: false,
    arrow: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        arrow: true,
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        arrow: true,
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        arrow: true,
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const settings2 = {
    infinite: false,
    dots: false,
    arrow: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        arrow: true,
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        arrow: true,
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        arrow: true,
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const getRewardLevel = (level) => {
    if (undefined !== level) {
      return (intl.formatMessage({ id: "generic.status_level." + level }));
    }
  }

  const getStatutCss = (reward) => {
    switch(reward.availableStatus) {
      case "1":
        return 'account-level bg-bronze';
      case "2":
        return 'account-level bg-silver';
      case "3":
        return 'account-level bg-gold';
      case "4":
        return 'account-level bg-platinum';
    }
  }



  const rewardsHtml = () => {
    let html = '';
    if (rewards && rewards.length > 0) {
      html = rewards.map((reward, index) => {
        if (true !== reward.hidden) {
          return (
            <div key={index}>
              <Link to="/rewards/reward-single" className="reward-item" key={index} state={{id: reward.id}}>
                <div className="img-wrapper" style={{backgroundImage: "url(" + reward.imgThumb + ")"}}>
                  <div className={getStatutCss(reward)}>
                    <span className="text">{getRewardLevel(reward.availableStatus)}</span>
                  </div>
                </div>
                <div className="reward-content-wrapper">
                  <div className="reward-title text"><strong>{reward.title}</strong></div>
                  <p className="reward-description" dangerouslySetInnerHTML={{__html: reward.shortDescription}}></p>
                  <div className="text dark-red"><span className="icon-ticket mr-2"></span><strong>{reward.offer}</strong>
                  </div>
                </div>
              </Link>
            </div>
          )
        }
      }
        );
      return html;
    }
  };

  const getSlider = () => {
    if (rewards && rewards.length < 3) {
      return (<Slider {...settings2}>
        {rewardsHtml()}
      </Slider>)
    } else {
      return (
        <Slider {...settings1}>
          {rewardsHtml()}
        </Slider>
      )
    }
  }

  return(
    <div className="reward-row">
      {getSlider()}
    </div>
  )
}

RewardSlider.propTypes = {
  rewards: PropTypes.node.isRequired,
}

export default RewardSlider
