import React, { useEffect, useState } from "react"
import {Link, navigate} from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import instance from "../../components/api/httpclient";
import RewardSlider from "./reward-slider"

import { FormattedMessage, useIntl } from "react-intl";
import imgVehicle from "../../assets/images/vehicle/JUKE_F15-A1-min.png";
import ReactImageFallback from "react-image-fallback";
import GenericVehicle from "../../assets/images/vehicle/generic.png";
import moment from "moment";

import Timeline from "../maintenance/timeline";

class BodyClass extends React.Component {
  // static propTypes = {
  //   name: React.PropTypes
  // }
  // static defaultProps = {
  //   name: ''
  // }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const RewardList = () => {
  const [rewardsByCategory, setRewardsByCategory] = useState([]);
  const [allRewards, setAllRewards] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [onlyAvailableToMe, setOnlyAvailableToMe] = useState(false);
  const [filteredCategory, setFilteredCategory] = useState(null);
  const [onlyInBelgium, setOnlyInBelgium] = useState(false);
  const [onlyInNetherlands, setOnlyInNetherlands] = useState(false);
  const [currentCars, setCurrentCars] = useState('');
  const [currentUser, setCurrentUser] = useState('');
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const [downloadRemainingDays, setDownloadRemainingDays] = useState(null);
  const [selectedCar, setSelectedCar] = useState(''); // Ajout pour gatsby ?

  const intl = useIntl();

  useEffect(() => {
    instance.get("/rewards/locale/" + intl.locale,
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then( (response) => {
        setRewardsByCategory(response.data);
        let _rewards = [];
        response.data.forEach(category => {
          category.rewards.forEach(reward => {
            reward['category'] = category;
            _rewards.push(reward)
          })
        })
        setRewards(_rewards);
        setAllRewards(_rewards);
      }).catch((error) => {
        console.log('error', error);
        if (error.toString().includes("403")) {
          setHasAccess(false);
        }
      });

    setTooltipOpened(!JSON.parse(localStorage.getItem('currentUser')).welcomeRewards);
  }, []);


  const getIconCss = (eventLevel) => {
    switch(parseInt(eventLevel)) {
      case 1:
        return 'bronze';
      case 2:
        return 'silver';
      case 3:
        return 'gold';
      case 4:
        return 'platinum';
    }
  }

  const getCars = () => {
    if (currentCars.length > 0 && selectedCar !== undefined) {
      return (

          <div className="dropdown">
            <a className="dropdown-toggle text darker-grey" href="#" role="button" id="dropdownMenuLink"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {/*<img src={selectedCar.image} alt={selectedCar.name} className="img-fluid align-middle"
                            width="50" />*/}
              <ReactImageFallback src={selectedCar.image} fallbackImage={GenericVehicle}
                                  alt={selectedCar.name} className="img-fluid align-middle" width="50" />
              <span className="d-none d-md-inline ml-3"><strong>{selectedCar.name}</strong> {selectedCar.licencePlate}</span>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              {currentCars.map(car => (
                  <a className="dropdown-item mb-2 mb-lg-1" href="" key={car.id} onClick={e => Timeline.selectCar(car,
                      e)}><strong>{car.name}</strong> {car.licencePlate}</a>
              ))}
            </div>
          </div>
      );
    }
  };

  const hasCars = () => {

    if (typeof window !== 'undefined') {
      if (JSON.parse(localStorage.getItem('currentCars'))) {
        return JSON.parse(localStorage.getItem('currentCars')).length
      }
    }
  }

  const closeTooltip = (e) => {
    instance.post('/welcome',
      {
        type: 'rewards'
      },
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      }).then(function (response) {
        if (typeof window !== 'undefined') {
          localStorage.setItem('currentUser', JSON.stringify(response.data.user));
          setCurrentUser(typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {});
        }
      });
    e.preventDefault();
    setTooltipOpened(false);
  };

  const pageContent = () => {
    return (
      <div className="col-lg-8">
        {tooltipOpened &&

          <div className="tooltip-custom dark-red-border mb-4 mt-3 mx-3 mx-sm-0 mt-lg-0">
            <a href="#" className="close dark-red" onClick={(e) => closeTooltip(e)}><span>+</span></a>
            <h3 className="dark-red"><span className="icon-questions align-middle fs-20 mr-2"></span>{intl.formatMessage({ id: "reward_shop.notice_box.welcome" })}</h3>
            <p className="mb-0 dark-red">{intl.formatMessage({ id: "reward_shop.notice_box.description" })}</p>
          </div>
        }

        {hasCars() === 0 &&
          <div className="bg-dark-red p-4 mb-4 rounded">
            <p className="text white mb-4">
              {intl.formatMessage({ id: "reward_shop.alert_box.no_associated_vehicle" })}</p>

            <div className="text-center">
              <Link to="/mycars/add-car" className="button dark-red bg-white d-inline-block position-relative">
                <div className="icon-car icon-car-new dark-red mr-2 d-inline-block"></div>
                <span>{intl.formatMessage({ id: "reward_shop.alert_box.button" })}</span></Link>
            </div>
          </div>
        }

        <div className="white-container">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({ id: "reward_shop.title" })}</h1>
          <p className="mb-4">{intl.formatMessage({ id: "reward_shop.description" })}</p>

          {/* FILTERS */}
          <div className="filters ml-3">
            <div className="category-filter mb-3">
              <div data-toggle="buttons" className="btn-group-toggle">
                <label className={ onlyAvailableToMe === true ? "btn btn-category" : "btn btn-category active" } onClick={(e) => filterRewardsByCategory(e, null)}>
                  <input type="radio" name="categories" id="all" autoComplete="off" checked={filteredCategory === null ? "checked": ""}  />{intl.formatMessage({id: "reward_shop.filters.all"})}
                </label>
                <label className="btn btn-category" onClick={(e) => filterRewardsByCategory(e, 'bestOf')}>
                  <input type="radio" name="categories" id="beftOf" autoComplete="off" checked={filteredCategory === 'bestOf' ? "checked": ""} />{intl.formatMessage({id: "reward_shop.filters.best_of"})}
                </label>
                {rewardsByCategory.map(category => (
                    <label className="btn btn-category" onClick={(e) => filterRewardsByCategory(e, category.id)}>
                      <input type="radio" name="categories" id="dayNight" autoComplete="off" checked={filteredCategory === category.id ? "checked": ""} />{category.name}
                    </label>
                ))}
              </div>
            </div>
            <div className="countries-filter">
              <div className="form-group">
                <div className="d-flex flex-wrap">
                  <div className="mr-4">
                    <div className="checkbox-custom">
                      <input type="checkbox" id="available" name="only_available_to_me" checked={onlyAvailableToMe ? "checked": ""} className="form-check-input" onChange={(e) => onlyAvailableToMeChange(e)}/>
                      <label htmlFor="available" title="" className="form-check-label">{intl.formatMessage({id: "reward_shop.filters.only_available_to_me"})}</label>
                    </div>
                  </div>
                  <div className="mr-4">
                    <div className="checkbox-custom">
                      <input type="checkbox" id="onlyBelgium"  name="only_in_belgium" checked={onlyInBelgium ? "checked": ""} className="form-check-input" onChange={(e) => onlyInBelgiumChange(e)}/>
                      <label htmlFor="onlyBelgium" title="" className="form-check-label">{intl.formatMessage({id: "reward_shop.filters.only_in_belgium"})}</label>
                    </div>
                  </div>
                  <div className="mr-4">
                    <div className="checkbox-custom">
                      <input type="checkbox" id="onlyNl" name="only_in_netherlands" checked={onlyInNetherlands ? "checked": ""} className="form-check-input" onChange={(e) => onlyInNetherlandsChange(e)}/>
                      <label htmlFor="onlyNl" title="" className="form-check-label">{intl.formatMessage({id: "reward_shop.filters.only_in_netherlands"})}</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          {/* Reward-list */}
          <div className="row no-gutters">
            {rewards.map((reward, index) => (
                <div className="col-6 col-lg-3 mb-3" key={index}>
                  <Link {...(reward.downloadRemainingDays > 0 ? {'data-toggle': 'modal', 'data-target': '#modalAlready'} : {'to': '/rewards/reward-single'})} onClick={(e) => setDownloadRemainingDays(reward.downloadRemainingDays)} className="reward-item" key={index} state={{id: reward.id}}>
                    <div className={`img-wrapper position-relative  ${reward.downloadRemainingDays > 0 ? "already" : ""}`} style={{backgroundImage: "url(" + reward.imgThumb + ")"}}>
                      {reward.downloadRemainingDays > 0 &&
                          <div className="downloaded-reward ">
                            <span className="already-text">
                            {intl.formatMessage({id: "Already"})} <br/>{intl.formatMessage({id: "downloaded"})}
                            </span>
                          </div>
                      }
                      <span className={`icon-win icon-win-text position-absolute ${getIconCss(reward.status)}`}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </span>
                    </div>
                    <div className="reward-content-wrapper">
                      <div className="d-inline bg-dark-red white rounded px-2">
                        <span>{reward.category.name}</span>
                      </div>
                      <div className="reward-title text"><strong>{reward.title}</strong></div>
                      <p className="reward-description" dangerouslySetInnerHTML={{__html: reward.shortDescription}}></p>
                      <div className="text dark-red text-uppercase"><span className="icon-ticket mr-2"></span><strong>{reward.offer}</strong></div>
                    </div>
                  </Link>
                </div>
            ))}
          </div>
        </div>
      </div>
    )
  };

  /* Filter methods  */
  const setFilterCategory = (e) => {
    console.log(e.target)
    console.log(e.target.value)
    setFilteredCategory(e.target.checked);
    filterRewardsByCategory(e, filteredCategory, false, onlyInBelgium, onlyInNetherlands);
  }
  const onlyAvailableToMeChange = (e) => {
    setOnlyAvailableToMe(e.target.checked);
    if (e.target.checked) {
      setOnlyInNetherlands(false);
      setOnlyInBelgium(false);
    }
    filterRewardsByCategory(e, null, e.target.checked, onlyInBelgium, onlyInNetherlands);
  }
  const onlyInBelgiumChange = (e) => {
    setOnlyInBelgium(e.target.checked);
    if (e.target.checked) {
      setOnlyInNetherlands(false);// N'est pas pris en compte dans l'appel de filterRewardsByCategory
      setOnlyAvailableToMe(false);
    }
    //filterRewardsByCategory(e, filteredCategory, onlyAvailableToMe, e.target.checked, onlyInNetherlands);
    filterRewardsByCategory(e, filteredCategory, onlyAvailableToMe, e.target.checked, false);
  }
  const onlyInNetherlandsChange = (e) => {
    setOnlyInNetherlands(e.target.checked);
    if (e.target.checked) {
      setOnlyInBelgium(false); // N'est pas pris en compte dans l'appel de filterRewardsByCategory
      setOnlyAvailableToMe(false);
    }
    //filterRewardsByCategory(e, filteredCategory, onlyAvailableToMe, onlyInBelgium, e.target.checked);
    filterRewardsByCategory(e, filteredCategory, onlyAvailableToMe, false, e.target.checked);
  }

  const filterRewardsByCategory = (e, category, onlyAvailableToMeParam, onlyInBelgiumParam, onlyInNetherlandsParam) => {
    setFilteredCategory(category);
    setRewards(allRewards);
    setOnlyAvailableToMe(false);

    let rewardsFilter = data => {

      if(category === null && false === onlyInBelgiumParam && false === onlyInNetherlandsParam && e.target.checked === true){
        setOnlyAvailableToMe(true)
      }

      if (onlyAvailableToMeParam && !data.availableToMe) {
        return false;
      }

      if (onlyInBelgiumParam && data.country !== 'BE') {
        return false;
      }

      if (onlyInNetherlandsParam && data.country !== 'NL') {
        return false;
      }

      if (category === 'bestOf') {
        if (!data.bestOf) {
          return false;
        }
      }

      if (category !== null && category !== 'bestOf') {
        if (data.categoryId !== category) {
          return false;
        }
      }

      return true;
    }
    setRewards(allRewards.filter(rewardsFilter));
  };

  const getStatutCss = (reward) => {
    switch(reward.availableStatus) {
      case "1":
        return 'account-level bg-bronze';
      case "2":
        return 'account-level bg-silver';
      case "3":
        return 'account-level bg-gold';
      case "4":
        return 'account-level bg-platinum';
    }
  }

  const userCantAccess = () => {
    return (
      <div className="col-lg-8">
        <div className="white-container">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({ id: "reward_shop.title" })}</h1>
          <p className="mb-4">{intl.formatMessage({ id: "reward_shop.description" })}</p>

          <div className="col-lg-8">
            <p className="mb-4">
              {intl.formatMessage({ id: "reward_shop.message.user_cant_access" })}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.rewards.reward-list" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={hasAccess ? pageContent() : userCantAccess()} currentPage="reward" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default RewardList
